@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}

.font {
  font-family: "Montserrat", sans-serif !important;
}
.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputBorder {
  padding-left: 16px !important;
  border: solid 1px #bbb;
  border-radius: 6px;
}
.pointer {
  cursor: pointer !important;
}
.mx-1 {
  margin-left: 8px;
  margin-right: 8px;
}
.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}
.ml-1 {
  margin-left: 8px;
}
.mr-1 {
  margin-right: 8px;
}
.max-h {
  height: 100%;
}
.mb-1 {
  margin-bottom: 8px;
}
.flex-row-s-center {
  display: flex;
  align-items: center;
}
.p-1 {
  padding: 8px;
}

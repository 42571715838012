.switchButtonContainer {
  position: relative;
  overflow: hidden;
  height: 32px;
}

.switchButton {
  color: white;
  padding: 4px;
  border-radius: 6px;
  height: 32px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  transition: all cubic-bezier(0.11, 0.87, 0.48, 1.26) 0.3s;
}

.optionYes {
  background: #3880ff;
}
.optionNo {
  background: #bbb;
}
